<div class="background"></div>

<div class="outer-div">
  <div class="inner-div">
   
    <div class="back">
      <div class="social-media-wrapper">
        <a href="https://www.linkedin.com/in/rajhawaldar/" class="social-icon"
          ><i class="fab fa fa-linkedin" aria-hidden="true"></i
        ></a>
        <a href="https://github.com/rajhawaldar/" class="social-icon"
          ><i class="fab fa fa-github" aria-hidden="true"></i
        ></a>
        <a href="https://www.twitter.com/HawaldarRaj/" class="social-icon"
          ><i class="fab fa fa-twitter" aria-hidden="true"></i
        ></a>
        <a  href="https://medium.com/@rajhawaldar/" class="social-icon"
          ><i class="fab fa fa-medium" aria-hidden="true"></i
        ></a>
        <a href="mailto:rajhawaldar24@gmail.com?subject = Feedback&body = Hi Raj! I liked your portfolio"
          class="social-icon"
          target="_blank"
          rel="tooltip"
          title="send me an email"
          ><i class="fab fa fa-envelope" aria-hidden="true"></i
        ></a>
      </div>
    </div>
  </div>
</div>

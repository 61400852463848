
  <div class="container">
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="h4 text-center mb-4 title">Achievements</div>
        <div class="nav-align-center">
          <ul class="nav nav-pills nav-pills-primary" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#web-development" role="tablist">
                <i class="fa fa-laptop" aria-hidden="true"></i>
              </a>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  <div class="section" id="about">
    <div class="container">
      <div class="card" data-aos="fade-up" data-aos-offset="10">
        <div class="row">
          <div class="col-lg-16 col-md-16">
            <div class="card-body">
              <ul style="font-size: 16px;font-weight: 500;">
                
                  <div class="row" style="margin:30px">
                    <div class="col-sm-12"><i class="fa fa-star" aria-hidden="true"></i> Bridgenext Spot Award in June 2023</div>
                </div>
              
            
              
                <div class="row" style="margin:30px">
                  <div class="col-sm-12"><i class="fa fa-star" aria-hidden="true"></i>Harbinger Superstar for September 2021</div>
                </div>
              
             
                <div class="row" style="margin:30px">
                
                <div class="col-sm-12"><i class="fa fa-star" aria-hidden="true"></i>Annual Award for Excellence in Technical Contribution - (Software Development) - June 2021</div>
              </div>
            
              
                <div class="row" style="margin:30px">
                
                <div class="col-sm-12"><i class="fa fa-star" aria-hidden="true"></i>Part of DevOps Committee which helps small projects in an organization to setup DevOps culture</div>
              </div>
            
              

              <div class="row" style="margin:30px">
                <div class="col-sm-12"><i class="fa fa-star" aria-hidden="true"></i>Harbinger Superstar for June 2021</div>
              </div>
            
              <div class="row" style="margin:30px">
                
                <div class="col-sm-12"><i class="fa fa-star" aria-hidden="true"></i>Distinguished Contributor Award for December 2019 – January 2020</div>
              </div>
            
               <div class="row" style="margin:30px">
                
                <div class="col-sm-12"><i class="fa fa-star" aria-hidden="true"></i>Distinguished Contributor Award for August-Sept 2019</div>
              </div>
              <div class="row" style="margin:30px">
                <div class="col-sm-12"><i class="fa fa-star" aria-hidden="true"></i>Nominated for “Shining Star Campus Rookie of the year 2019” in Harbinger Systems</div>
              </div>
              <div class="row" style="margin:30px">
                
                <div class="col-sm-12"><i class="fa fa-star" aria-hidden="true"></i>Distinguished Contributor Award for August-Sept 2019</div>
              </div>
            </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="section" id="experience">
  <div class="container cc-education">
    <div class="h4 text-center mb-4 title">Certifications</div>
    <div class="colorlib-narrow-content">
      <div class="row">
        <div class="col-md-12">
          <div class="timeline-centered">
            <article
              class="timeline-entry animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div class="timeline-entry-inner">
                <div class="timeline-icon color-4">
                  <i class="icon-pen2"></i>
                </div>

                <div class="timeline-label">
                  <h2>Microsoft Certified: Azure Fundamentals</h2>
                  <h4>
                    <a
                      href="https://www.credly.com/badges/cb6dccdc-a0f5-446e-ab95-654b85d4a1ca?source=linked_in_profile"
                      target="_blank"
                      >click here to verify my certification</a
                    >
                  </h4>
                </div>
                
              </div>
            </article>
            <article
              class="timeline-entry animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div class="timeline-entry-inner">
                <div class="timeline-icon color-4">
                  <i class="icon-pen2"></i>
                </div>

                <div class="timeline-label">
                  <h2>HackerRank Go (Basic) Certificate</h2>
                  <h4>
                    <a
                      href="https://www.hackerrank.com/certificates/7a3aaeeb31e6"
                      target="_blank"
                      >click here to verify my certification</a
                    >
                  </h4>
                </div>
                
              </div>
            </article>
            <article
              class="timeline-entry animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div class="timeline-entry-inner">
                <div class="timeline-icon color-4">
                  <i class="icon-pen2"></i>
                </div>

                <div class="timeline-label">
                  <h2>Advanced Golang</h2>
                  <h4>
                    <a
                      href="https://kodekloud.com/certificate-verification/2D0B1CA54AF7-2DFAD5A6FE16-2D0B11073489/"
                      target="_blank"
                      >click here to verify my certification</a
                    >
                  </h4>
                </div>
                
              </div>
            </article>
            <article
              class="timeline-entry animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div class="timeline-entry-inner">
                <div class="timeline-icon color-4">
                  <i class="icon-pen2"></i>
                </div>

                <div class="timeline-label">
                  <h2>Bash Scripting and Shell Programming (Linux Command Line)</h2>
                  <h4>
                    <a
                      href="https://harbingergroup.udemy.com/certificate/UC-92b39c49-8d9c-447e-8ac0-6dda32c2463c/"
                      target="_blank"
                      >click here to verify my certification</a
                    >
                  </h4>
                </div>
                
              </div>
            </article>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

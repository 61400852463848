import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  //baseUrl = environment.baseUrl;
ƒ
  constructor(
    private http: HttpClient
  ) { }

  projects:any = [
 
  ]
  about = `Working in the field of DevOps with over six years of experience .
My expertise spans Linux, Bash Scripting, Azure Cloud, Kubernetes, Docker, GitHub Workflows, Go Lang, ArgoCD and Terraform .
Slack bot development experience with Go programming language (TDD) .
I am passionate about exploring open-source tools and advocating for their use to streamline processes and enhance productivity .
I specialize in automation, finding innovative solutions to real-life problems, and making systems more efficient and reliable .
In my spare time, I enjoy reading tech blogs and surfing YouTube for the latest trends and developments in the tech world .
I am also passionate about mentoring and guiding newcomers to the industry, helping them navigate their career paths and achieve their goals`

  resumeurl = "https://1drv.ms/b/s!ApLu7F3yekG34F8-GFR3NjnY5gdO?e=fHfaYU"

  skillsData: any = [
    {
      'id': '5',
      'skill': 'Docker',
    },
    {
      'id': '6',
      'skill': 'Kubernetes',
    },
    {
      'id': '2',
      'skill': 'Azure Services',
    },
    {
      'id': '5',
      'skill': 'Linux',
    },
    {
      'id': '2',
      'skill': 'Go Lang',
    },
    {
      'id': '1',
      'skill':'GitHub Workflows'
    },
    {
      'id': '3',
      'skill': 'Bash Scripting',
    },
    {
      'id': '7',
      'skill': 'Jenkins',
    },
    {
      'id': '1',
      'skill': 'Terraform',
    },
    {
      'id': '1',
      'skill': 'ArgoCD',
    },
  ];


  educationData: any = [
    {
      'id': '1',
      'from_to_year': '2016 - 2018',
      'education': 'Post Graduate \'s Degree',
      'stream': 'Bachelor of Engineering',
      'info': `Aditya Silver Oak Institute is GTU Affiliated and ranks 7th in Gujurat
Completed B.E in Computer Engineering with 9.54 CGPA.  
Won the Best Student Award 3 times for excellent Academic records at College. 
Maintained above 9.1 spi in every semester of college academic .`,
      'institution': 'ADITYA SILVER OAK INSTITUTE OF TECHNOLOGY, AHMEDABAD'
    },
    {
      'id': '2',
      'from_to_year': '2013 - 2015',
      'education': 'Higher Secondary',
      'stream': 'Science and Mathematics',
      'institution': 'GYANDEEEP VIDHYALAYA,AHMEDABAD',
      'info': `The High School was mainly focus on the bases of science, mathematics, and Chemistry
There was also a good base on physics, mathematics and chemistry.
Completed my high school with 78%.`
    },
    {
      'id': '3',
      'from_to_year': '2012 - 2013',
      'education': 'Secondary  School',
      'stream': 'Science and Mathematics',
      'institution': 'GYANDEEEP VIDHYALAYA,AHMEDABAD',
      'info': `The Secondary aims at Maths , English , Science, Social Science, Litrature and Languages.
Completed my Secondary school with 84%.`
    }
  ];
  exprienceData: any = [
    {
      id: 1,
      current: true,
      company: 'Bridgenext',
      location: 'Pune, India',
      timeline: 'March 2022 to Present',
      projects: [{
        domain: 'Logistics',
        tech: 'Kubernetes, Docker, Azure, Terraform, ArgoCD, Helm, GitHub Actions, Go, Bash Script, JFrog, NewRelic',
        work: 'Experience of Managing 500+ microservices on K8s clusters . Involved in the development of CLI tool using Go to deploy microservices in Kubernetes (Initially when the service count was below 300) .Integrated that CLI tool with GitHub workflows to release microservices .Developed a Slack bot in Go lang to automate the deployment activities from slack .Migrated the release process from in-house cli tool to ArgoCD . Automating CI/CD tasks with the help of Bash Scripts',
      }]
    },
    {
      id: 2,
      company: 'Harbinger Systems Pvt. Ltd',
      location: 'Pune, India',
      timeline: 'JAN 2018  to March 2022',
      projects: [{
        domain: 'HR',
        tech: 'Azure DevOps Service, Azure Cloud Resources, Azure Self Hosted Agents and Deployment Groups',
        work: 'Complete ownership of biweekly Production releases .Reduced Production release duration from 45 mins to 15 mins  .Create and maintain 100+ CI/CD pipelines for Code/Infrastructure deployment using Azure DevOps .Created quality profiles, quality gates in SonarCloud and enforced them in the Azure DevOps pipelines .Qualys DAST integration with Azure DevOps Pipelines .Experience with the main-only branching strategy in TFVC .Automated release branches creation with PowerShell script for Teams Foundation Version Control (TFVC) .Manage cloud resources in Azure with ARM template, Azure PowerShell .Manage self-hosted agents and deployment groups .Manage Azure AD users and resources access .Hands-on Experience with YAML Pipelines .Migrated over 25 Applications from Rackspace to Azure Cloud which include creation and maintenance of development, staging & production environments',
      },{
        domain: 'DMS & Insurance',
        tech: 'Jenkins, GitHub',
        work: 'Migrated the entire version control from SVN to Git .Automated build and release deployment using Jenkins on Rackspace servers (QA, Pre-Prod environmenta .Worked as GitHub site Administrator (Managed GitHub Organization accounts) .Implemented PowerShell scripts for patch creation, JIRA ticket updates .Implemented GitHooks to maintain branch name and commit standards',
      }]

    },
  ]

  skills(): Observable<any> {
    
    return this.skillsData;
  }

  getProjects(): Observable<any> {
  
    return this.projects;
  }
  education(): Observable<any> {
    
    return this.educationData;
  }

  exprience(): Observable<any> {

    return this.exprienceData;
  }
}

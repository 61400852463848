<div class="section" id="reference">
        <div class="container cc-reference">
          <div class="h4 mb-4 text-center title">References</div>
          <div class="card" data-aos="zoom-in">
            <div class="carousel slide" id="cc-Indicators" data-ride="carousel">
              <ol class="carousel-indicators">
                <li class="active" data-target="#cc-Indicators" data-slide-to="0"></li>
                <li data-target="#cc-Indicators" data-slide-to="1"></li>
               
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row">
                    <div class="col-lg-2 col-md-3 cc-reference-header">
                      <img src="https://media.licdn.com/dms/image/D4E03AQGoV8h-oUwDnQ/profile-displayphoto-shrink_400_400/0/1713202544660?e=1727308800&v=beta&t=zcT2D6FedFRiyI7Md_OvIUmVOjKhsLK7eMiMonVSRNg" alt="Image" />
                    </div>
                    <div class="col-lg-10 col-md-10">
                    <p>
                      Over the course of almost two years, I had the pleasure of working with Raj and directly managing him for two years, and it was an outstanding experience.
He was one of the individuals who could make a significant contribution to any team. Raj has excellent technical skills and problem solving skills, and there is nothing he cannot accomplish. All you need to do is provide him with the statement of the problem and he will provide you with a detailed solution with proper documentation, as well as pros and cons.
As a proactive, hardworking and self-driven individual, he requires very little supervision, always taking full responsibility for his activities, which really impresses me.
The problem-solving skills he possesses are outstanding and he played a key role in the Azure migration project. </p>
<p>
In the beginning, he had just two years of experience. However, once we started working together, I realized his technical skills were comparable with those of a person with five or six years of experience, which is a clear indication of his technical ability.
In addition to having solid experience in Azure DevOps, PowerShell, and Azure services, he has implemented a number of innovative ideas that have significantly reduced overall build deployment time, branch creation time, backup times, and other processes.
I had an exceptional experience working with Raj, and I would be delighted to work with him again in the future.
                    </p>
                    <a class="recommendation" href="https://www.linkedin.com/in/rajhawaldar/details/recommendations/">View recommendation on Linkedin</a>
                    <div class="h5 mt-1">Sachin Suryawanshi </div>
                      <p class="category">Software Architect at Harbinger Systems</p>
                    </div>
                  </div>
                </div>
                
               
              </div>
            </div>
          </div>
        </div>
      </div>
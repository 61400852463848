<app-header></app-header>
<div class="page-content">
    <app-intro></app-intro>
    <app-about></app-about>
    <app-experience></app-experience>
    <app-portfolio></app-portfolio>
    <app-certification></app-certification>
    <app-reference></app-reference>
    <app-contact></app-contact>
</div>
<!-- <ngx-spinner bdColor="rgba(255,255,255,1)" size="medium" color="#033d5b" type="ball-spin-clockwise"></ngx-spinner> -->

<div class="section" id="about">
    <div class="container">
      <div class="card" data-aos="fade-up" data-aos-offset="10">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="card-body">
              <div class="h4 mt-0 title">About</div>
              <div>
                <ul>
                  <li *ngFor="let m of about1 | split">{{m}}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="card-body">
              <div class="h4 mt-0 title">Basic Information</div>
              <div class="row">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Date of Birth:</strong>
                </div>
                <div class="col-sm-8">August 24, 1995</div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Email:</strong>
                </div>
                <div class="col-sm-8">rajhawaldar24@gmail.com</div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Phone:</strong>
                </div>
                <div class="col-sm-8">+91 7020923672</div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Address:</strong>
                </div>
                <div class="col-sm-8">Pune, Maharashtra, India
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-4">
                  <strong class="text-uppercase">Language:</strong>
                </div>
                <div class="col-sm-8">English, Hindi, Marathi</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="section" id="experience">
    <div class="container cc-education">
        <div class="h4 text-center mb-4 title">Work Experience</div>
        <div class="colorlib-narrow-content">

            <div class="row">
                <div class="col-md-12">
                    <div class="timeline-centered">
                        <article *ngFor="let exp of workexp" class="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                            <div class="timeline-entry-inner">

                                <div class="timeline-icon color-2" *ngIf="exp.current">
                                    <i class="icon-pen2"></i>
                                </div>
                                <div class="timeline-icon color-3" *ngIf="!exp.current">
                                    <i class="icon-pen2"></i>
                                </div>
                                <div class="timeline-label">
                                    <h2>{{exp.company}}<span>- &nbsp;{{exp.timeline}}, {{exp.location}}</span></h2>
                                    <div class="project"  *ngFor="let project of exp.projects">
                                        <h4> {{project.domain}} Domain</h4>
                                        <h3> {{project.tech}}</h3>
                                        <ul>
                                          <li *ngFor="let work of project.work | split">{{work}}</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
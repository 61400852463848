<div class="background"></div>

<div class="outer-div">
  <div class="inner-div">
    <div class="front">
      <div class="front__bkg-photo"></div>
      <div class="front__face-photo"></div>
      <div class="front__text">
        <h3 class="front__text-header">Raj Hawaldar</h3>
        <p class="front__text-para"><i class="fas fa-map-marker-alt front-icons"></i>Pune, India</p>
        
        <a class="btn btn-primary" href={{cvUrl}} target="_blank" data-aos="zoom-in" data-aos-anchor="data-aos-anchor">Download CV</a>
      </div>
    </div>
  </div>
</div>
<div class="profile-page">
  
  <div class="wrapper">
    
      <div class="section">
        <div >
          <div class="button-container">
            <a
              class="btn btn-default btn-round btn-lg btn-icon"
              href="https://www.linkedin.com/in/rajhawaldar/"
              target="_blank"
              rel="tooltip"
              title="Follow me on Linkedin"
            >
              <i class="fa fa-linkedin"></i>
            </a>
            <a
              class="btn btn-default btn-round btn-lg btn-icon"
              href="https://github.com/rajhawaldar/"
              target="_blank"
              rel="tooltip"
              title="Follow me on Github"
            >
              <i class="fa fa-github"></i>
            </a>
            <a
              class="btn btn-default btn-round btn-lg btn-icon"
              href="https://medium.com/@rajhawaldar/"
              target="_blank"
              rel="tooltip"
              title="Follow me on Medium"
            >
              <i class="fa fa-medium"></i>
            </a>
            <a
              class="btn btn-default btn-round btn-lg btn-icon"
              href="https://www.linkedin.com/in/HawaldarRaj/"
              target="_blank"
              rel="tooltip"
              title="Follow me on Twitter"
            >
              <i class="fa fa-twitter"></i>
            </a>
            <a
              class="btn btn-default btn-round btn-lg btn-icon"
              href="mailto:rajhawaldar24@gmail.com?subject = Feedback&body = Hi Raj! I liked your portfolio"
              target="_blank"
              rel="tooltip"
              title="send me an email"
            >
              <i class="fa fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>
  </div>
</div>

<div class="section" id="skill">
    <div class="container">
        <div class="h4 text-center mb-4 title">Skills</div>
        <div class="card" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6" *ngFor="let skill of skills">
                        <div class="progress-container progress-primary">
                            <span class="progress-badge">{{skill.skill}}</span>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>